import * as Sentry from '@sentry/remix'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'

Sentry.init({
	dsn: 'https://b2d9da84400af32618ab6c0cd52d882d@o4508447549685760.ingest.us.sentry.io/4508447850037248',
	tracesSampleRate: 1,
	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
	],
})

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
		</StrictMode>,
	)
})
